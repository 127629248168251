import React from 'react';
import mega_logo from './images/mega_logo3.PNG';

function Header() {
    return (
        <header id="start">
            <img alt="" src={mega_logo} width="200"/>
        </header>
    )
}

export default Header
